import * as Request from './common/request';
import $ from 'jquery';
import React, { useState } from 'react';
import './App.less';

const forget = () => {
  $(".section").fadeOut(200);
  $(".forgot-pwd").fadeIn(200);
  $(".login-btn").removeClass("disabled")
  $(".account-input").val("")
  $(".password-input").val("")
};

const goback  = () => {
  $(".forgot-pwd").fadeOut(200);
  $(".section").fadeIn(200);
  $(".login-btn").removeClass("disabled")
  $(".email-input").val("")
};


const Member = (props) => {
  const [ account, setAccount ] = useState('');
  const [ password, setPassword ] = useState('');

  const login = () => {
    $(".login-btn").addClass("disabled")
    if ($(".account-input").val() === ""){
      $(".error-message-4").fadeIn(300,function () {
        $(this).delay(1000).fadeOut('slow');
        setTimeout(
          function () {
            $(".login-btn").removeClass("disabled")
          },1000
        )
      });
    } else if ($(".password-input").val() === ""){
      $(".error-message-5").fadeIn(300,function () {
        $(this).delay(1000).fadeOut('slow');
        setTimeout(
          function () {
            $(".login-btn").removeClass("disabled")
          },1000
        )
      });
    } else {
      Request.post({
        url: '/u/login',
        data: {
          account,
          password,
          type:0,
        }
      }).then(res => {
        $(".error-message").delay(1500).fadeIn(300,function () {
          $(this).delay(1500).fadeOut('slow');
          setTimeout(
            function () {
              $(".login-btn").removeClass("disabled")
            },1000
          )
        });
      });
    }
  };

  return (
    <div className='section'>
      <div className='logo'></div>
      <div className='login-content'>
        Connectez-vous pour gérer votre compte.
      </div>
      <div className='login-info'>
        <div className='login-account'>
          <div className='login-placeholder'>
            Identifiant
          </div>
          <div className='login-input-wraper'>
            <input type="text" className='account-input' onChange={e => {
            setAccount(e.target.value)}}></input>
          </div>
          <div className="error-message-4">Saisissez votre identifiant de compte.</div>
        </div>
        <div className='login-password'>
          <div className='login-placeholder'>
            Mot de passe
          </div>
          <div className='login-input-wraper'>
            <input type="password" className='password-input' onChange={e => {
            setPassword(e.target.value);
          }}></input>
          </div>
          <div className="error-message">Identifiant ou Mot de passe incorrect</div>
          <div className="error-message-5">Entrez votre mot de passe.</div>
        </div>
      </div>
      <div className='login-btn' onClick={() => {login()}}>
        SE CONNECTER
      </div>
      <div className='forgot-password' onClick={() => {forget()}}>
        J’ai oublié mon mot de passe
      </div>
    </div>
  )
};

const ForgetPassword = (props) => {
  const [ email, setEmail ] = useState('');

  const login2 = () => {
    $(".login-btn").addClass("disabled")
    if ($(".email-input").val() === ""){
      $(".error-message-3").fadeIn(300,function () {
        $(this).delay(1000).fadeOut('slow');
        setTimeout(
          function () {
            $(".login-btn").removeClass("disabled")
          },1000
        )
      });
    } else {
      Request.post({
        url: '/u/login',
        data: {
          email,
          type:1,
        }
      }).then(res => {
        $(".error-message-2").delay(1500).fadeIn(300,function () {
          $(this).delay(1500).fadeOut('slow');
          setTimeout(
            function () {
              $(".login-btn").removeClass("disabled")
            },1000
          )
        });
      });
    }
  };

  return (
    <div className='forgot-pwd'>
      <div className='section2'>
        <div className='login-title'>
          Mot de passe oublié</div>
        <div className='login-content'>
          Saisissez votre adresse e-mail de connexion pour réinitialiser votre mot de passe.
        </div>
        <div className='login-info'>
          <div className='login-account'>
            <div className='login-placeholder'>
              Mon e-mail
            </div>
            <div className='login-input-wraper'>
              <input type="text" className='email-input' onChange={e => {
              setEmail(e.target.value)}}></input>
            </div>
            <div className="error-message-2">Votre compte n'existe pas.</div>
            <div className="error-message-3">Entrez votre adresse email.</div>
          </div>
        </div>
        <div className='login-btn' onClick={() => {login2()}}>
          Envoyer
        </div>
        <div className='go-back' onClick={() => {goback()}}>
          Retourner à la page de connexion
        </div>
      </div>
    </div>
  )
};

function App() {
  return (
    <div className="App">
      <div className="top-container">
        <div className="container">
          <div className='mask'>
            <Member></Member>
            <ForgetPassword></ForgetPassword>
            <div className="footer">
              ©2022 TWK capital management - tous droits réservés.
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default App;
