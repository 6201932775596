import 'whatwg-fetch';

const apiPrefix = 'http://filter.moidas.com';

export const post = async (settings = {}) => {
	let url = apiPrefix + settings.url;
	let response = await window.fetch(url, {
		method: 'POST',
		headers: {
			"content-type":"application/json; charset=utf-8",
		},
		credentials:'include',
		body: JSON.stringify(settings.data || {})
	});

	response = await response.json();
	return response;
};